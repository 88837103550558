<template>
  <div class="lotteryBox">
    <router-view />
    <footer
      class="footer h5MaxWidth display alignStart alignStretch"
      id="footer"
    >
      <div class="footer-nav flex1">
        <div>
          <ul class="display alignCenter">
            <li
              name="home"
              :class="tab === 0 ? 'flex1 homeNav active' : 'flex1 homeNav'"
              @click="changeTab(0)"
            >
              <div class="">
                <div class="icon display alignCenter justifyCenter radius50P">
                  <i class="iconfont icon-Stable"></i>
                </div>
                <p>Home</p>
              </div>
            </li>
            <li
              name="forum"
              :class="tab === 1 ? 'flex1 MBoardNav active' : 'flex1 MBoardNav'"
              @click="changeTab(1)"
            >
              <div class="">
                <div class="icon display alignCenter justifyCenter radius50P">
                  <i class="iconfont icon-message-text-1"></i>
                </div>
                <p>MBoard</p>
              </div>
            </li>
            <li
              name="account"
              :class="
                tab === 2 ? 'flex1 accountNav active' : 'flex1 accountNav'
              "
              @click="changeTab(2)"
            >
              <div class="">
                <div class="icon display alignCenter justifyCenter radius50P">
                  <i class="iconfont icon-wallet-02"></i>
                </div>
                <p>Account</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="onlineService">
        <div class="likeness">
          <a href="javascript:;" @click="goKf()">
            <img src="../images/OnlineService.png" alt="" />
          </a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: 2,
    };
  },
  methods: {
    changeTab(tab) {
      this.tab = tab;
      if (this.tab === 0) {
        this.$router.push("/home/index");
      } else if (this.tab === 1) {
        this.$router.push("/home/mboard");
      } else if (this.tab === 2) {
        this.$router.push("/home/mine");
      }
      sessionStorage.setItem("tab", this.tab);
    },
    goKf() {
      window.location.href =
        "https://api.whatsapp.com/send/?phone=919115969974&text&type=phone_number&app_absent=0";
    },
    goPage(page) {
      this.$router.push(page);
    },
  },
  mounted() {
    this.tab = sessionStorage.getItem("tab")
      ? +sessionStorage.getItem("tab")
      : 0;
  },
};
</script>

<style scoped lang="less">
@charset "utf-8";

.footer {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999;
}

.footer-nav {
  margin: 0 auto;
  overflow: hidden;
  -moz-box-shadow: 0px 0px 20px 5px rgba(0, 40, 14, 0.1);
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 40, 14, 0.1);
  box-shadow: 0px 0px 20px 5px rgba(0, 40, 14, 0.1);
}

.footer-nav > div {
  height: 100%;
  min-height: 60px;
  background-color: var(--b_1);
  border-width: 0 5px 0 0;
  border-color: var(--a_1);
  border-style: solid;
  -webkit-backdrop-filter: blur(5px);
  -ms-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.footer-nav ul li {
  padding: 0;
  text-align: center;
  display: block;
  text-decoration: none;
  position: relative;
  -webkit-filter: grayscale(50%);
  -moz-filter: grayscale(50%);
  -ms-filter: grayscale(50%);
  -o-filter: grayscale(50%);
  filter: grayscale(50%);
  filter: gray;
}

.footer-nav ul li p {
  font-size: 16px;
  color: #fff;
  position: relative;
  /* transform: translate(0, 50px);
    -webkit-transition: all 0.4s ease-in;
    -ms-transition: all 0.4s ease-in;
    transition: all 0.4s ease-in;
    opacity: 0.8; */
}

.footer-nav ul li .icon {
  display: inline-flex;
  min-height: 25px;
  position: relative;
  transform: translateY(2px);
  -webkit-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.footer-nav ul li .icon img {
  width: 90%;
  max-width: 35px;
}

.footer-nav ul li .icon i {
  margin: 0 auto;
  display: inline-block;
  font-size: 36px;
  color: #fff;
  opacity: 0.9;
}

.footer-nav ul li.active > div {
  padding: 10px 5px;
  display: inline-block;
  position: relative;
}

.footer-nav ul li.active .icon {
  width: 50px;
  height: 50px;
  transform: translateY(-5px);
  background-color: #fff;
}

.footer-nav ul li.active .icon i {
  font-weight: 700;
  color: var(--a_1);
  opacity: 1;
}

.footer-nav ul li.active p {
  color: var(--black1);
  transform: translateY(0);
  display: none;
}

.onlineService {
  width: 80px;
  overflow: hidden;
  -moz-box-shadow: 0 -3px 20px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 -3px 20px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 -3px 20px 1px rgba(0, 0, 0, 0.2);
  z-index: 99;
}

.onlineService .likeness,
.onlineService .likeness img {
  width: 100%;
}
</style>
